.uploadContainer {
	flex: 1;
	margin-bottom: 10px;
	justify-content: center;
	display: flex;
}

.flex_1 {
	flex: 1;
}
.simulations {
	margin-top: 10px;
}
.messageContainer {
	padding: 100px;
}
