@keyframes spin {
	from {
		transform: rotate(0deg);
	}
	to {
		transform: rotate(-360deg);
	}
}

.label.Mui-focused {
	color: 'primary';
}
.MuiInput-underline:after {
	border-bottom-color: 'primary';
}
.MuiOutlinedInput-root .Mui-focused fieldset {
	border-color: 'primary';
}
